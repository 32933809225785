import * as RadioGroup from "@radix-ui/react-radio-group";
import { Check } from "lucide-react";
import { DEVICE_PLATFORMS } from "sutro-common";

import { Badge } from "../../ui/badge";

export function PlatformRadioItem({
  platform,
  icon,
  title,
  description,
  published,
  disabled,
}: {
  platform: DEVICE_PLATFORMS;
  icon: React.ReactNode;
  title: string;
  description: string;
  published?: boolean;
  disabled?: boolean;
}) {
  return (
    <RadioGroup.Item
      value={platform}
      className="group flex w-full items-center rounded border px-6 py-4 data-[state=checked]:border-primary"
      disabled={disabled}
    >
      <div className="text-muted-foreground group-data-[state=checked]:text-primary">
        {icon}
      </div>
      <div className="ml-4 flex grow flex-col items-start">
        <span className="text-lg font-semibold text-muted-foreground group-data-[state=checked]:text-foreground">
          {title}
        </span>
        <span className="text-sm text-muted-foreground">
          {published ? (
            <span>
              Email{" "}
              <a href="mailto:support@withsutro.com" className="underline">
                support@withsutro.com
              </a>{" "}
              to unpublish
            </span>
          ) : (
            description
          )}
        </span>
      </div>
      {published && (
        <Badge
          variant="xs"
          className="mr-2 border-border-info-muted bg-bg-info-muted px-2.5 py-1 text-text-info"
        >
          Published
        </Badge>
      )}
      <RadioGroup.Indicator asChild>
        <Check className="size-6 text-primary" />
      </RadioGroup.Indicator>
    </RadioGroup.Item>
  );
}
