import { Gem } from "lucide-react";
import { PropsWithChildren, useLayoutEffect, useRef, useState } from "react";
import { PostHogFlags } from "sutro-common/feature-flags/posthog-flags";
import { StudioEntitlement } from "sutro-common/studio-entitlements";
import { useEventListener } from "usehooks-ts";

import { InfoTooltip } from "~/components/app/info-tooltip";
import { useFeatureFlags } from "~/lib/hooks/use-feature-flag";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";

type Props = PropsWithChildren<{
  tier: "Starter" | "Pro";
  entitlementKey: StudioEntitlement["entitlementKey"];
  small?: boolean;
}>;

function TierBadge({
  tier,
  small,
  right = "0px",
}: {
  tier: "Starter" | "Pro";
  small: boolean;
  right?: string;
}) {
  return (
    <div
      className="absolute inset-y-0 my-auto flex size-min select-none flex-row items-center justify-center gap-1 space-x-1 rounded-md border border-border-warning-muted bg-warning-muted-active px-2.5 py-1 text-xs text-text-warning"
      style={{ right }}
    >
      <InfoTooltip Icon={Gem}>
        This feature requires a {tier} subscription. Click here to upgrade.
      </InfoTooltip>
      <Gem className="size-3 text-text-warning" />
      {small || <div>{tier}</div>}
    </div>
  );
}

/**
 * This component should be used to wrap any link or button that requires a premium subscription
 *
 * @param tier The tier required to access the feature
 * @param entitlementKey The entitlement key required to access the feature
 * @param small Whether the badge should just be the Gem icon, or should include the name of the required tier
 */
export function PremiumWrapper({
  tier,
  children,
  entitlementKey,
  small = false,
}: Props) {
  const flags = useFeatureFlags(PostHogFlags.PremiumFeatures);

  const { entitlements } = useEntitlements();
  const isMissingEntitlement = entitlements.every(
    (e) => e.entitlementKey !== entitlementKey
  );
  const featureIsBlocked =
    isMissingEntitlement && flags[PostHogFlags.PremiumFeatures];

  const wrapperDiv = useRef<HTMLDivElement>(null);
  const [wrappedDivRightPadding, setWrappedDivRightPadding] = useState("0px");

  /**
   * We use this to make sure that the badge is suitably inset from the right edge
   */
  useLayoutEffect(() => {
    if (wrapperDiv.current === null) {
      return;
    }
    const wrappedDiv = wrapperDiv.current.children[0];

    const computedStyle = getComputedStyle(wrappedDiv);
    setWrappedDivRightPadding(computedStyle.getPropertyValue("padding-right"));

    if (featureIsBlocked) {
      wrappedDiv.className += " cursor-default";
    } else {
      wrappedDiv.className = wrappedDiv.className.replace(
        " cursor-default",
        ""
      );
    }
  }, [featureIsBlocked]);

  /**
   * We listen during the capture phase to prevent the click event from reaching
   * the actual link
   */
  useEventListener(
    "click",
    (evt) => {
      if (featureIsBlocked) {
        evt.stopPropagation();
        evt.preventDefault();
      }
    },
    wrapperDiv,
    { capture: true }
  );

  return (
    <div className="relative m-0 p-0" ref={wrapperDiv}>
      {children}
      {featureIsBlocked && (
        <TierBadge tier={tier} small={small} right={wrappedDivRightPadding} />
      )}
    </div>
  );
}
