import * as RadioGroup from "@radix-ui/react-radio-group";
import { ButtonIds } from "sutro-analytics";
import { pick } from "sutro-common/collection-helpers/pick";
import { useShallow } from "zustand/react/shallow";

import { usePublish } from "~/lib/use-publish";

import { Button } from "../../ui/button";
import { DialogFooter, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Label } from "../../ui/label";
import { RadioGroupItem } from "../../ui/radio-group";
import { DetailToggle } from "./detail-toggle";

export function Step2Ios({
  onContinue,
  onBack,
}: {
  onContinue: () => void;
  onBack: () => void;
}) {
  const { hasAppleDevAccount, setHasAppleDevAccount } = usePublish(
    useShallow(pick("hasAppleDevAccount", "setHasAppleDevAccount"))
  );

  return (
    <>
      <DialogHeader>
        <DialogTitle>Publish on iOS</DialogTitle>
      </DialogHeader>
      <RadioGroup.Root
        value={
          hasAppleDevAccount === undefined
            ? undefined
            : hasAppleDevAccount
              ? "yes"
              : "no"
        }
        onValueChange={(newValue) => setHasAppleDevAccount(newValue === "yes")}
        className="flex flex-col gap-2"
      >
        <DetailToggle
          contentRight={
            <RadioGroupItem id="hasAppleDevAccountYes" value="yes" />
          }
          contentLeft={
            <Label
              className="text-sm font-medium"
              htmlFor="hasAppleDevAccountYes"
            >
              I already have an Apple Developer account
            </Label>
          }
        ></DetailToggle>
        <DetailToggle
          contentRight={<RadioGroupItem id="hasAppleDevAccountNo" value="no" />}
          contentLeft={
            <Label
              className="text-sm font-medium"
              htmlFor="hasAppleDevAccountNo"
            >
              I do not have an Apple Developer account
            </Label>
          }
          showDetail={hasAppleDevAccount === false}
          detailContent={
            <p className="text-sm text-muted-foreground">
              This is required for you to be able to deploy your app to the
              Apple App Store.
              <br />
              <br />
              You can find detailed instructions on how to setup your Apple
              Developer account here. If you would like Sutro to setup your
              Apple Developer account for you, please email us at
              support@sutro.xyz.
            </p>
          }
        ></DetailToggle>
      </RadioGroup.Root>
      <DialogFooter>
        <Button
          variant="secondary"
          onClick={onBack}
          testId={ButtonIds.PUBLISH_FLOW_PREVIOUS_STEP}
        >
          Back
        </Button>
        <Button
          onClick={onContinue}
          disabled={!hasAppleDevAccount}
          testId={ButtonIds.PUBLISH_FLOW_NEXT_STEP}
        >
          Continue
        </Button>
      </DialogFooter>
    </>
  );
}
