import { CheckCircle2, Loader2, XCircle } from "lucide-react";
import { useCallback, useState } from "react";

import { PricingTierData } from "~/data/pricing-tiers";
import { cn } from "~/lib/utils";

/**
 * This component is for displaying a pricing tier, including the name, rate, features and a CTA button
 */

type Props = PricingTierData & {
  /**
   * Whether or not this is the recommended tier
   */
  recommended?: boolean;
  /**
   * Whether this is the user's current plan
   */
  currentPlan?: boolean;
  /**
   * The CTA button text
   */
  actionDescription?: string;
  /**
   * Whether the CTA button is disabled
   */
  actionDisabled?: boolean;
  /**
   * The action to take when the CTA button is clicked
   */
  action?: () => Promise<void>;
  /**
   * Optional tab index for a11y
   */
  tabIndex?: number;
};

function CtaButton({
  action,
  actionDescription,
  actionDisabled,
  tabIndex,
  recommended,
}: Pick<
  Props,
  "action" | "actionDescription" | "actionDisabled" | "recommended"
> & {
  tabIndex: number;
}) {
  const [performingAction, setPerformingAction] = useState(false);

  const performAction = useCallback(async () => {
    if (actionDisabled || action === undefined) {
      return;
    }
    setPerformingAction(true);
    await action();
    setPerformingAction(false);
  }, [action, actionDisabled]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={cn(
        "flex h-12 w-full items-center justify-around rounded-xl border px-4 py-2",
        {
          "border-color-border": !recommended,
          "border-brand-orange": recommended,
        },
        {
          "bg-brand-orange": recommended,
        },
        {
          "cursor-pointer": !actionDisabled,
          "cursor-default": actionDisabled,
          "bg-disabled": actionDisabled,
          "text-text-disabled": actionDisabled,
        }
      )}
      onClick={performAction}
      role="button"
      tabIndex={tabIndex}
    >
      <div
        className={cn("size-min text-nowrap text-base font-medium", {
          "text-black": !recommended,
          "text-white": recommended,
        })}
      >
        {performingAction ? (
          <>
            <Loader2 className="animate-spin" />
          </>
        ) : (
          <>{actionDescription}</>
        )}
      </div>
    </div>
  );
}

export function PricingTier({
  tierName,
  price,
  description,
  offeringDescription,
  actionDescription,
  actionDisabled = false,
  action = () => Promise.resolve(void 0),
  checkMarks,
  xMarks = [],
  currentPlan = false,
  tabIndex = 0,
  recommended,
}: Props) {
  return (
    <div
      className={cn("relative size-min w-full rounded-xl border", {
        "border-color-border": !recommended,
        "border-brand-orange": recommended,
      })}
    >
      {recommended && (
        <div className="relative -top-px flex flex-row justify-center gap-2.5 rounded-t-xl border border-brand-orange bg-brand-orange p-2.5">
          <div className="w-min text-nowrap text-xs font-semibold text-white">
            Most popular
          </div>
        </div>
      )}
      <div className="box-content flex size-max w-[200px] flex-col gap-6 p-6">
        <div className="flex w-full flex-col gap-4">
          <div className="flex h-6 w-full flex-row items-center justify-between leading-5">
            <div className="text-sm font-semibold">{tierName}</div>
            {currentPlan && (
              <div className="size-min text-nowrap rounded-md border border-success bg-success-muted px-2 py-1 text-xs font-medium text-success">
                Current Plan
              </div>
            )}
          </div>
          <div className="gap-0">
            <div className="text-xs font-semibold text-zinc-500">
              Starting at
            </div>
            <div className="flex flex-row items-center gap-1">
              <div className="text-2xl font-medium leading-9 text-black">
                ${price >= 0 ? price : "--"}
              </div>
              <div className="text-xs font-medium text-zinc-500">/ month</div>
            </div>
            <div className="text-xs font-medium">{description}</div>
          </div>
        </div>
        <div className="flex h-40 flex-col gap-2">
          <div className="text-xs font-bold">{offeringDescription}:</div>
          <div className="flex flex-col gap-2">
            {checkMarks.map((checkMark) => (
              <div key={checkMark} className="flex flex-row items-center gap-2">
                <CheckCircle2 fill="#15aa58" stroke="white" size={16} />

                <div className="text-xs font-normal">{checkMark}</div>
              </div>
            ))}
            {xMarks.map((xMark) => (
              <div key={xMark} className="flex flex-row  items-center gap-2">
                <XCircle fill="#71717a" stroke="white" size={16} />
                <div className="text-xs font-normal">{xMark}</div>
              </div>
            ))}
          </div>
        </div>
        {actionDescription && (
          <CtaButton
            recommended={recommended}
            actionDisabled={actionDisabled}
            action={action}
            actionDescription={actionDescription}
            tabIndex={tabIndex}
          />
        )}
      </div>
    </div>
  );
}
