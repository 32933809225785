import { throwError } from "@dancrumb/fpish";
import { Description } from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ButtonIds, StudioEventTypes } from "sutro-analytics";
import { isTierUpgrade, TierName } from "sutro-common/studio-entitlements";

import { PricingTable } from "~/components/app/pricing-table/pricing-table";
import { TierPurchaseForm } from "~/components/app/tier-purchase-form";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import { SutroApi } from "~/lib/sutro-api";
import { either } from "~/lib/sutro-api/Api";
import { useAnalytics } from "~/lib/use-analytics";

import { useEntitlements } from "../EntitlementsProvider/entitlements-provider";

export type UpgradeRequest = {
  heading: string;
  subHeading: string;
  recommendedTier?: TierName;
  onUpgrade: (newTier: TierName) => void;
  onCancel: () => void;
  // This is to help track analytics; it tells us where was this requested from
  context: string;
};

type TierManagementContext = {
  requestUpgrade: (upgradeRequest: UpgradeRequest) => void;
};

const TierManagementReactContext = createContext<TierManagementContext>({
  requestUpgrade: () => {
    console.warn(
      "Looks like you have useRequestUpgrade outside of the TierManagementProvider"
    );
  },
});

export function TierManagementProvider({ children }: PropsWithChildren) {
  const api = SutroApi.getApi().authenticate();
  const [open, setOpen] = useState(false);
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [recommendedTier, setRecommendedTier] = useState<TierName>("Pro");
  const { currentSubscription } = useEntitlements();
  const onUpgrade = useRef<UpgradeRequest["onUpgrade"]>(() => {});
  const onCancel = useRef<UpgradeRequest["onCancel"]>(() => {});
  const [closeIsCancel, setCloseIsCancel] = useState(true);
  const [selectedTier, selectTier] = useState<null | TierName>(null);
  const [showPurchaseForm, setShowPurchaseForm] = useState(false);
  const [context, setContext] = useState("");
  const { track } = useAnalytics();

  useEffect(() => {
    if (open) {
      track(StudioEventTypes.UPGRADE_ACCOUNT_DIALOG_OPENED);
    } else {
      track(StudioEventTypes.UPGRADE_ACCOUNT_DIALOG_CLOSED);
    }
  }, [open]);

  const handleDialogClose = useCallback(() => {
    setOpen(false);
    if (closeIsCancel) {
      onCancel.current();
      track(StudioEventTypes.TIER_CHANGE_CANCELED);
    }
  }, [closeIsCancel, track]);

  const handleUpgrade = useCallback(
    (newTier: TierName) => {
      onUpgrade.current(newTier);
      track(StudioEventTypes.TIER_CHANGE_COMPLETED, {
        newTier,
      });
      setCloseIsCancel(false);
    },
    [track]
  );

  const changeTier = useCallback(
    async (
      newTier: TierName,
      onComplete: () => Promise<void>,
      onChangeCancel: () => Promise<void>
    ) => {
      if (newTier === currentSubscription.tier.name) {
        return;
      }
      track(StudioEventTypes.BUTTON_CLICK, {
        buttonId: ButtonIds.CHANGE_TIER,
        currentTier: currentSubscription.tier.name,
        newTier,
        context,
      });
      if (newTier === "Custom") {
        window.open(`https://calendly.com/tom-ha/sutro-custom-tier`, "_blank");
        return;
      }
      selectTier(newTier);
      if (currentSubscription.tier.name === "Free") {
        setShowPurchaseForm(true);
      } else {
        const upgradingTier = isTierUpgrade(
          currentSubscription.tier.name,
          newTier
        );
        await api
          .patch("/users/current/subscription", {
            type: upgradingTier ? "upgrade" : "downgrade",
            toFree: newTier === "Free",
          })
          .then(
            either((err) => {
              void onChangeCancel();
              throwError(err);
            }).or(() => {
              void onComplete();
              return handleUpgrade(newTier);
            })
          );
      }
    },
    [api, context, currentSubscription.tier.name, handleUpgrade, track]
  );

  const requestUpgrade = useCallback(
    ({
      heading: _heading,
      subHeading: _subHeading,
      recommendedTier: _recommendedTier,
      onUpgrade: _onUpgrade,
      onCancel: _onCancel,
      context: _context,
    }: UpgradeRequest) => {
      setHeading(_heading);
      setSubHeading(_subHeading);
      setRecommendedTier(_recommendedTier ?? "Pro");
      setContext(_context);
      onUpgrade.current = _onUpgrade;
      onCancel.current = _onCancel;

      setCloseIsCancel(true);
      setOpen(true);
    },
    []
  );

  return (
    <TierManagementReactContext.Provider value={{ requestUpgrade }}>
      {children}
      {showPurchaseForm && selectedTier ? (
        <Dialog open={true} onOpenChange={() => selectTier(null)}>
          <DialogHeader></DialogHeader>
          <DialogContent>
            <TierPurchaseForm
              onPurchase={() => {
                handleUpgrade(selectedTier);
                handleDialogClose();
              }}
              tierName={selectedTier}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog open={open} onOpenChange={handleDialogClose}>
          <DialogContent className="max-w-6xl">
            <VisuallyHidden.Root>
              <DialogTitle className="hidden">{heading}</DialogTitle>
              <Description>
                This dialog shows the various subscription tiers that can be
                subscribed to
              </Description>
            </VisuallyHidden.Root>
            <PricingTable
              heading={heading}
              subHeading={subHeading}
              recommendedTier={recommendedTier}
              changingToTier={selectedTier}
              onChangeTier={changeTier}
            />
          </DialogContent>
        </Dialog>
      )}
    </TierManagementReactContext.Provider>
  );
}

export const useRequestUpgrade = () => {
  return useContext(TierManagementReactContext).requestUpgrade;
};
