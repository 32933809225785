import * as RadioGroup from "@radix-ui/react-radio-group";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { ButtonIds } from "sutro-analytics";
import { z } from "zod";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "~/components/ui/form";
import { appPublishSchema } from "~/lib/use-publish";

import { Button } from "../../ui/button";
import { DialogFooter, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { RadioGroupItem } from "../../ui/radio-group";
import { DetailToggle } from "./detail-toggle";

const step3AndroidSchema = appPublishSchema.pick({
  expoOrganizationName: true,
  expoPersonalAccessToken: true,
});

export function Step3Android({
  onBack,
  onContinue,
}: {
  onBack: () => void;
  onContinue: () => void;
}) {
  // This is not really relevant in the broader context so this is just local state
  const [hasExpoAccount, setHasExpoAccount] = useState<boolean | undefined>(
    undefined
  );

  const { trigger, control, clearErrors } =
    useFormContext<z.infer<typeof step3AndroidSchema>>();

  const handleNextStep = async () => {
    const isValid = await trigger([
      "expoOrganizationName",
      "expoPersonalAccessToken",
    ]);

    if (isValid) {
      onContinue();
    }
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle>Publish on Android</DialogTitle>
      </DialogHeader>
      <RadioGroup.Root
        value={
          hasExpoAccount === undefined
            ? undefined
            : hasExpoAccount
              ? "yes"
              : "no"
        }
        onValueChange={(newValue) => setHasExpoAccount(newValue === "yes")}
        className="flex flex-col gap-2"
      >
        <DetailToggle
          contentRight={<RadioGroupItem id="hasExpoAccountYes" value="yes" />}
          contentLeft={
            <Label className="text-sm font-medium" htmlFor="hasExpoAccountYes">
              <span>
                I already have an{" "}
                <a
                  href="https://expo.dev"
                  target="_blank"
                  className="text-primary underline"
                  rel="noreferrer"
                >
                  Expo
                </a>{" "}
                account
              </span>
            </Label>
          }
          showDetail={hasExpoAccount === true}
          detailContent={
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium">
                Setup your Expo account to deploy apps
              </span>
              <p className="text-sm text-muted-foreground">
                Expo allows you to quickly and easily deploy app store-ready
                builds to the Android Play Store. You can find detailed
                instructions on how to setup your Expo account to deploy apps
                here.{" "}
              </p>
              <FormField
                control={control}
                name="expoOrganizationName"
                render={({ field, fieldState }) => (
                  <FormItem className="mb-3">
                    <FormLabel>Expo organization name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        error={fieldState.error?.message}
                        placeholder="e.g. Dynamo Inc..."
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("expoOrganizationName");
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="expoPersonalAccessToken"
                render={({ field, fieldState }) => (
                  <FormItem className="mb-3">
                    <FormLabel>Expo personal access token</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        error={fieldState.error?.message}
                        placeholder="e.g. J7YH-86EQ..."
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("expoPersonalAccessToken");
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          }
        ></DetailToggle>
        <DetailToggle
          contentRight={<RadioGroupItem id="hasExpoAccountNo" value="no" />}
          contentLeft={
            <Label className="text-sm font-medium" htmlFor="hasExpoAccountNo">
              <span>
                I do not have an{" "}
                <a
                  href="https://expo.dev"
                  target="_blank"
                  className="text-primary underline"
                  rel="noreferrer"
                >
                  Expo
                </a>{" "}
                account
              </span>
            </Label>
          }
          showDetail={hasExpoAccount === false}
          detailContent={
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium">
                Create an Expo account
              </span>
              <p className="text-sm text-muted-foreground">
                Expo allows you to quickly and easily deploy app store-ready
                builds to the Android Play Store. You can find detailed
                instructions on how to setup your Expo account here.{" "}
              </p>
              <FormField
                control={control}
                name="expoOrganizationName"
                render={({ field, fieldState }) => (
                  <FormItem className="mb-3">
                    <FormLabel>Expo organization name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        error={fieldState.error?.message}
                        placeholder="e.g. Dynamo Inc..."
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("expoOrganizationName");
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="expoPersonalAccessToken"
                render={({ field, fieldState }) => (
                  <FormItem className="mb-3">
                    <FormLabel>Expo personal access token</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        error={fieldState.error?.message}
                        placeholder="e.g. J7YH-86EQ..."
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors("expoPersonalAccessToken");
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          }
        ></DetailToggle>
      </RadioGroup.Root>
      <DialogFooter>
        <Button
          variant="secondary"
          onClick={onBack}
          testId={ButtonIds.PUBLISH_FLOW_PREVIOUS_STEP}
        >
          Back
        </Button>
        <Button
          onClick={handleNextStep}
          testId={ButtonIds.PUBLISH_FLOW_NEXT_STEP}
        >
          Continue
        </Button>
      </DialogFooter>
    </>
  );
}
