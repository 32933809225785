import { throwError } from "@dancrumb/fpish";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useCallback, useMemo } from "react";
import { TierName } from "sutro-common/studio-entitlements";

import appConfig from "~/app.config";
import { StudioError } from "~/lib/studio-error";
import { SutroApi } from "~/lib/sutro-api";
import { useProfile } from "~/lib/use-profile";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";

const stripePromise = loadStripe(appConfig.stripePublicKey, {
  apiVersion: "2024-06-20",
});

type Props = {
  onPurchase: () => void;
  tierName: TierName;
};
export function TierPurchaseForm({ onPurchase, tierName }: Props) {
  const { tiers, refreshEntitlements } = useEntitlements();
  const { refreshUser } = useProfile();

  const tierPrice = useMemo(() => {
    if (tierName === "Free") {
      return { id: "free", price: 0 };
    }
    const matchingTier = tiers.find((t) => t.name === tierName);
    if (!matchingTier) {
      throw new StudioError("Tier not found", {
        context: { tierName, tiers },
      });
    }
    return matchingTier.price;
  }, [tierName, tiers]);

  const fetchClientSecret = useCallback(() => {
    const payload = new URLSearchParams();
    payload.append("priceId", tierPrice.id);
    return SutroApi.getApi()
      .authenticate()
      .get<{
        sessionId: string;
        clientSecret: string;
      }>("/users/current/entitlements_checkout_session", { payload })
      .then((result) => {
        if (result.isLeft()) {
          return throwError(result.getLeft());
        }

        return result.getRight().clientSecret;
      });
  }, [tierPrice.id]);

  const onComplete = useCallback(async () => {
    await Promise.all([refreshEntitlements(), refreshUser()]);
    onPurchase();
  }, [onPurchase, refreshEntitlements, refreshUser]);

  const options = { fetchClientSecret, onComplete };

  return (
    <EmbeddedCheckoutProvider
      // We need a new instance of this provider for each tier purchase form
      key={tierPrice.id}
      stripe={stripePromise}
      options={options}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
}
