export function PlatformIosIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1453_4290)">
        <path
          d="M22.1576 0.871094C22.2321 0.871094 22.3066 0.871094 22.3853 0.871094C22.5679 3.1278 21.7066 4.81401 20.6597 6.0351C19.6325 7.24777 18.226 8.4239 15.951 8.24544C15.7992 6.02105 16.662 4.45991 17.7075 3.24162C18.677 2.10624 20.4546 1.09592 22.1576 0.871094Z"
          fill="currentColor"
        />
        <path
          d="M29.0455 24.3598C29.0455 24.3822 29.0455 24.4019 29.0455 24.423C28.4061 26.3593 27.4942 28.0188 26.3813 29.5589C25.3653 30.9571 24.1203 32.8386 21.8974 32.8386C19.9765 32.8386 18.7006 31.6034 16.7319 31.5697C14.6495 31.536 13.5043 32.6025 11.6002 32.8709C11.3824 32.8709 11.1646 32.8709 10.9511 32.8709C9.55291 32.6686 8.42456 31.5613 7.60253 30.5636C5.17861 27.6156 3.30551 23.8075 2.95703 18.9344C2.95703 18.4566 2.95703 17.9803 2.95703 17.5025C3.10457 14.0149 4.79921 11.1792 7.0517 9.80499C8.24048 9.0743 9.8747 8.45181 11.6944 8.73003C12.4743 8.85088 13.271 9.11786 13.9694 9.38203C14.6312 9.63637 15.4589 10.0874 16.2429 10.0635C16.7741 10.0481 17.3024 9.77126 17.8378 9.57594C19.406 9.00966 20.9432 8.36047 22.9695 8.66539C25.4047 9.03355 27.133 10.1155 28.201 11.7849C26.141 13.0959 24.5124 15.0716 24.7906 18.4454C25.0379 21.5101 26.8197 23.3031 29.0455 24.3598Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1453_4290">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.871094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
