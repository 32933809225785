import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "sonner";
import { DEVICE_PLATFORMS, SutroError } from "sutro-common";
import { pick } from "sutro-common/collection-helpers/pick";
import { useShallow } from "zustand/react/shallow";

import { Dialog, DialogContent } from "~/components/ui/dialog";
import { useStudio } from "~/lib/hooks/use-studio";
import { useStudioDialog } from "~/lib/hooks/use-studio-dialog";
import { StudioError } from "~/lib/studio-error";
import { useProfile } from "~/lib/use-profile";
import {
  AppleTeamType,
  appPublishSchema,
  PublishFormType,
  usePublish,
} from "~/lib/use-publish";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";
import { useRequestUpgrade } from "~/providers/TierManagementProvider";

import { Step1 } from "./step-1";
import { Step2Android } from "./step-2-android";
import { Step2Ios } from "./step-2-ios";
import { Step2Web } from "./step-2-web";
import { Step3Android } from "./step-3-android";
import { Step3Ios } from "./step-3-ios";
import { Step3Web } from "./step-3-web";
import { Step4Android } from "./step-4-android";
import { Step4Ios } from "./step-4-ios";
import { Step5Android } from "./step-5-android";
import { Step5Ios } from "./step-5-ios";
import { Step6Ios } from "./step-6-ios";

export const PUBLISH_DIALOG_ID = "showPublish";

export function PublishDialog({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const refreshUser = useProfile((s) => s.refreshUser);
  const { currentSubscription, refreshEntitlements } = useEntitlements();
  const requestUpgrade = useRequestUpgrade();
  const {
    currentStep,
    setCurrentStep,
    executePublish,
    fetchPublishedProducts,
    publishedProducts,
  } = usePublish(
    useShallow(
      pick(
        "currentStep",
        "setCurrentStep",
        "executePublish",
        "fetchPublishedProducts",
        "publishedProducts"
      )
    )
  );
  const [, openPublishDialog] = useStudioDialog(PUBLISH_DIALOG_ID);

  const {
    workingDefinition: { appMeta },
    refreshProduct,
  } = useStudio(useShallow(pick("workingDefinition", "refreshProduct")));
  const productId = useStudio((s) => s.product?.id);

  const onCancel = useCallback(() => {
    onOpenChange(false);
  }, [onOpenChange]);

  const formDefaultValues = useMemo(
    () => ({
      appIconImage: undefined,
      platform: undefined,

      // TODO: The following two must come from user profile
      expoOrganizationName: "",
      expoPersonalAccessToken: "",

      expoProjectId: "",
      expoProjectSlug: "",
      privacyPolicyLink: appMeta.ppLink ?? "",
      splashScreenImage: undefined,
      termsOfServiceLink: appMeta.termsLink ?? "",

      appleTeamId: "",
      appleTeamType: AppleTeamType.INDIVIDUAL,
      ascApiKeyFile: undefined,
      ascKeyId: "",
      ascIssuerId: "",
    }),
    [appMeta]
  );

  const form = useForm<PublishFormType>({
    resolver: zodResolver(appPublishSchema),
    mode: "onSubmit",
    defaultValues: formDefaultValues,
  });

  const platform = form.watch("platform");

  const handleRequestUpgrade = useCallback(() => {
    const onUpgrade = async () => {
      await Promise.all([refreshEntitlements(), refreshUser()]);
      openPublishDialog();
    };
    const _onCancel = () => {
      openPublishDialog();
    };

    onOpenChange(false);

    const subHeading =
      currentSubscription.tier.name === "Free"
        ? "Publish your app to Web, App Store, and Google Play Store by upgrading your account."
        : "Publish your app to the App Store and Google Play Store by upgrading your account.";

    requestUpgrade({
      onUpgrade,
      onCancel: _onCancel,
      heading: "Upgrade to publish",
      subHeading,
      context: "publish-dialog",
    });
  }, [
    currentSubscription.tier.name,
    onOpenChange,
    openPublishDialog,
    refreshEntitlements,
    refreshUser,
    requestUpgrade,
  ]);

  useEffect(() => {
    if (!open) {
      form.reset(formDefaultValues);
      setCurrentStep(1);
    } else {
      fetchPublishedProducts();
    }
  }, [form, formDefaultValues, open, setCurrentStep]);

  const handlePublishRequest = async () => {
    if (productId === undefined) {
      toast.error("Failed to publish app.");
      throw new StudioError("Product id undefined on publish");
    }
    try {
      await executePublish({
        ...form.getValues(),
        productId,
      });

      setCurrentStep(currentStep + 1);
    } catch (err) {
      const errorMessage =
        err instanceof SutroError
          ? err.message
          : "Unknown error. Please try again later.";
      toast.error(errorMessage);
    } finally {
      await refreshProduct();
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-2xl">
        <FormProvider {...form}>
          {currentStep === 1 && (
            <Step1
              publishedProducts={publishedProducts}
              onContinue={() => setCurrentStep(currentStep + 1)}
              onCancel={onCancel}
              onRequestUpgrade={handleRequestUpgrade}
            />
          )}
          {currentStep === 2 && platform === DEVICE_PLATFORMS.MOBILE_WEB && (
            <Step2Web
              onContinue={handlePublishRequest}
              onBack={() => setCurrentStep(currentStep - 1)}
            />
          )}
          {currentStep === 2 && platform === DEVICE_PLATFORMS.IOS && (
            <Step2Ios
              onContinue={() => setCurrentStep(currentStep + 1)}
              onBack={() => setCurrentStep(currentStep - 1)}
            />
          )}
          {currentStep === 2 && platform === DEVICE_PLATFORMS.ANDROID && (
            <Step2Android
              onContinue={() => setCurrentStep(currentStep + 1)}
              onBack={() => setCurrentStep(currentStep - 1)}
            />
          )}
          {currentStep === 3 && platform === DEVICE_PLATFORMS.IOS && (
            <Step3Ios
              onBack={() => setCurrentStep(currentStep - 1)}
              onContinue={() => setCurrentStep(currentStep + 1)}
            />
          )}
          {currentStep === 3 && platform === DEVICE_PLATFORMS.ANDROID && (
            <Step3Android
              onBack={() => setCurrentStep(currentStep - 1)}
              onContinue={() => setCurrentStep(currentStep + 1)}
            />
          )}
          {currentStep === 4 && platform === DEVICE_PLATFORMS.IOS && (
            <Step4Ios
              onBack={() => setCurrentStep(currentStep - 1)}
              onContinue={() => setCurrentStep(currentStep + 1)}
            />
          )}
          {currentStep === 5 && platform === DEVICE_PLATFORMS.IOS && (
            <Step5Ios
              onBack={() => setCurrentStep(currentStep - 1)}
              onContinue={handlePublishRequest}
            />
          )}
          {currentStep === 4 && platform === DEVICE_PLATFORMS.ANDROID && (
            <Step4Android
              onBack={() => setCurrentStep(currentStep - 1)}
              onContinue={handlePublishRequest}
            />
          )}
          {currentStep === 6 && platform === DEVICE_PLATFORMS.IOS && (
            <Step6Ios />
          )}
          {currentStep === 5 && platform === DEVICE_PLATFORMS.ANDROID && (
            <Step5Android />
          )}
          {currentStep === 3 && platform === DEVICE_PLATFORMS.MOBILE_WEB && (
            <Step3Web />
          )}
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
