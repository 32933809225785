import { useSearchParams } from "@remix-run/react";
import { useCallback, useMemo } from "react";

/**
 * This hook provides the three basic things needed for a modal:
 * - A boolean to determine if the modal is open
 * - A function to open the modal
 * - A function to close the modal
 *
 * It operates via the URL search params, so the modal state is preserved
 */
export function useStudioDialog(
  modalId: string
): [isOpen: boolean, openModal: () => void, closeModal: () => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const isOpen = searchParams.has(modalId);

  const openModal = useCallback(() => {
    setSearchParams((prev) => {
      prev.set(modalId, "true");
      return prev;
    });
  }, [modalId, setSearchParams]);

  const closeModal = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete(modalId);
      return prev;
    });
  }, [modalId, setSearchParams]);

  return useMemo(
    () => [isOpen, openModal, closeModal],
    [closeModal, isOpen, openModal]
  );
}
