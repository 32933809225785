import * as RadioGroup from "@radix-ui/react-radio-group";
import { ButtonIds } from "sutro-analytics";
import { pick } from "sutro-common/collection-helpers/pick";
import { useShallow } from "zustand/react/shallow";

import { usePublish } from "~/lib/use-publish";

import { Button } from "../../ui/button";
import { DialogFooter, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Label } from "../../ui/label";
import { RadioGroupItem } from "../../ui/radio-group";
import { DetailToggle } from "./detail-toggle";

export function Step2Android({
  onContinue,
  onBack,
}: {
  onContinue: () => void;
  onBack: () => void;
}) {
  const { hasPlaystoreDevAccount, setHasPlaystoreDevAccount } = usePublish(
    useShallow(pick("hasPlaystoreDevAccount", "setHasPlaystoreDevAccount"))
  );

  return (
    <>
      <DialogHeader>
        <DialogTitle>Publish on Android</DialogTitle>
      </DialogHeader>
      <RadioGroup.Root
        value={
          hasPlaystoreDevAccount === undefined
            ? undefined
            : hasPlaystoreDevAccount
              ? "yes"
              : "no"
        }
        onValueChange={(newValue) =>
          setHasPlaystoreDevAccount(newValue === "yes")
        }
        className="flex flex-col gap-2"
      >
        <DetailToggle
          contentRight={
            <RadioGroupItem id="hasAppleDevAccountYes" value="yes" />
          }
          contentLeft={
            <Label
              className="text-sm font-medium"
              htmlFor="hasAppleDevAccountYes"
            >
              I already have a Google Play Console account
            </Label>
          }
        ></DetailToggle>
        <DetailToggle
          contentRight={<RadioGroupItem id="hasAppleDevAccountNo" value="no" />}
          contentLeft={
            <Label
              className="text-sm font-medium"
              htmlFor="hasAppleDevAccountNo"
            >
              I do not have a Google Play Console account
            </Label>
          }
          showDetail={hasPlaystoreDevAccount === false}
          detailContent={
            <p className="text-sm text-muted-foreground">
              This is required for you to be able to deploy your app to the
              Google Play Store.
              <br />
              <br />
              You can find detailed instructions on how to setup your Google
              Play Console account here. If you would like Sutro to setup your
              Google Play Console account for you, please email us at
              support@sutro.xyz.
            </p>
          }
        ></DetailToggle>
      </RadioGroup.Root>
      <DialogFooter>
        <Button
          variant="secondary"
          onClick={onBack}
          testId={ButtonIds.PUBLISH_FLOW_PREVIOUS_STEP}
        >
          Back
        </Button>
        <Button
          onClick={onContinue}
          disabled={!hasPlaystoreDevAccount}
          testId={ButtonIds.PUBLISH_FLOW_NEXT_STEP}
        >
          Continue
        </Button>
      </DialogFooter>
    </>
  );
}
