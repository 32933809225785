import { pick } from "sutro-common/collection-helpers/pick";
import { useShallow } from "zustand/react/shallow";

import { useDevOptions } from "../../../lib/hooks/use-dev-options";
import { useStudio } from "../../../lib/hooks/use-studio";
import { StudioError } from "../../../lib/studio-error";
import { ToggleButton } from "../toggle-button";

export const DevOptionsView = () => {
  const { devOptions: options, setDevOptions } = useDevOptions();
  const {
    fetchNewBundleForStudioAndUpdateState,
    product,
    sessionId,
    skipLogIn,
    validDefinition,
  } = useStudio(
    useShallow(
      pick(
        "fetchNewBundleForStudioAndUpdateState",
        "product",
        "sessionId",
        "skipLogIn",
        "validDefinition"
      )
    )
  );
  return (
    <div className="px-10 py-5">
      <h2 className="mb-6 text-lg font-semibold">Additional option(s)</h2>
      <div className="flex flex-row items-center justify-start gap-4">
        <ToggleButton
          label={options.shouldGetPostPluginDefinition ? "Enabled" : "Enable"}
          checked={options.shouldGetPostPluginDefinition}
          onCheckedChange={() => {
            const newValue = !options.shouldGetPostPluginDefinition;
            setDevOptions({
              shouldGetPostPluginDefinition: newValue,
            });
            if (validDefinition && newValue) {
              // This should always be true
              if (product) {
                fetchNewBundleForStudioAndUpdateState(validDefinition, {
                  previewUser: "Admins",
                  skipLogIn,
                  sessionId,
                  returnPostPluginDefinition: newValue,
                  productId: product?.id,
                });
              } else {
                throw new StudioError(
                  "Product undefined when fetching new bundle for dev options."
                );
              }
            }
          }}
          disableWhenChecked={false}
        />
        Should retrieve the Post-Plugin definition from compilation.
      </div>
    </div>
  );
};
