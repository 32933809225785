import { ArrowRight, Rocket } from "lucide-react";

import { Button } from "~/components/ui/button";

export function Step6Ios() {
  return (
    <div className="flex flex-col items-center py-6">
      <Rocket className="size-12 text-primary" />
      <span className="mt-4 text-2xl font-semibold">All done (for now)!</span>
      <span className="mt-2 text-center">
        Your app is being built! To publish your app to the Apple App Store,
        there are some additional steps you need to take first.
      </span>
      <span className="mt-6 text-sm">
        Check out the Sutro’s step-by-step guide for how to publish your app to
        the Apple App Store.
      </span>
      <Button className="mt-2">
        <div className="flex items-center gap-2">
          <span>Step-by-step guide</span>
          <ArrowRight className="size-4" />
        </div>
      </Button>
    </div>
  );
}
