import { NavLink, useNavigate } from "@remix-run/react";
import {
  Bell,
  BookOpen,
  HelpCircle,
  Home,
  LogOut,
  MessagesSquare,
  Puzzle,
  Rocket,
  Save,
  User,
  X,
} from "lucide-react";
import {
  CSSProperties,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { toast } from "sonner";
import {
  ButtonIds,
  DropdownIds,
  FormIds,
  StudioEventTypes,
} from "sutro-analytics";
import { PostHogFlags } from "sutro-common/feature-flags/posthog-flags";

import logo from "~/assets/sutro.svg";
import { PosthogNavButton } from "~/components/app/devtools/posthog-nav-button";
import { Button } from "~/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import useBreakpoints from "~/lib/hooks/use-breakpoint";
import { useFeatureFlags } from "~/lib/hooks/use-feature-flag";
import { useStudio } from "~/lib/hooks/use-studio";
import { useStudioDialog } from "~/lib/hooks/use-studio-dialog";
import { SutroApi } from "~/lib/sutro-api";
import { either } from "~/lib/sutro-api/Api";
import { useAnalytics } from "~/lib/use-analytics";
import { useProfile } from "~/lib/use-profile";
import { cn } from "~/lib/utils";
import { useIntercom } from "~/providers/IntercomProvider/intercom-provider";

import { DEV_TOOLS_DIALOG_ID, DevToolsNavButton } from "../devtools/dev-tools";
import { FeatureRequestDialog } from "../feature-request-dialog";
import { PUBLISH_DIALOG_ID } from "../publish/publish-dialog";
import { StudioUserAvatar } from "../studio-user-avatar";

function TopNavigationBarLink({
  label,
  href,
  icon,
}: {
  label: string;
  href: string;
  icon: ReactElement;
}) {
  const breakpoints = useBreakpoints();
  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        cn(
          "inline-flex items-center space-x-2 border-b-4 px-4 pb-5 pt-6 text-sm font-medium text-white transition-colors",
          {
            "border-blue-600": isActive,
            "border-transparent hover:border-blue-900": !isActive,
          }
        )
      }
    >
      {icon}
      {breakpoints.sm && <span>{label}</span>}
    </NavLink>
  );
}

export function BelowNavigationBarFullSizeContainer({
  children,
  className,
  style,
}: {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <div
      className={cn("flex h-[calc(100%-68px)] justify-center", className)}
      style={style}
    >
      <div className="size-full max-w-screen-2xl">{children}</div>
    </div>
  );
}

function SutroLogo() {
  const { track } = useAnalytics();
  return (
    <NavLink
      to="/"
      onClick={() => {
        track(StudioEventTypes.BUTTON_CLICK, {
          buttonId: ButtonIds.DESIGNER_TOP_NAV_SUTRO_LOGO,
        });
      }}
    >
      <img src={logo} className="mr-8 h-6 text-white" alt="Sutro logo" />
    </NavLink>
  );
}

function ProfileMenu() {
  const { logout, currentUser } = useProfile();

  const { track } = useAnalytics();
  return (
    <DropdownMenu
      onOpenChange={(open) => {
        if (open) {
          track(StudioEventTypes.DROPDOWN_CLICK, {
            dropdownId: DropdownIds.DESIGNER_TOP_NAV_PROFILE_DROPDOWN,
          });
        }
      }}
    >
      <DropdownMenuTrigger
        data-testId={DropdownIds.DESIGNER_TOP_NAV_PROFILE_DROPDOWN}
      >
        <StudioUserAvatar
          studioUser={currentUser}
          className="size-8 cursor-pointer"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <a href="/user" target="_blank">
            <DropdownMenuItem>
              <User className="mr-2 size-4" />
              <span>User settings</span>
            </DropdownMenuItem>
          </a>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => logout()}
          data-testId={ButtonIds.LOGOUT}
        >
          <LogOut className="mr-2 size-4" />
          <span>Logout</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function AssistanceMenu() {
  const breakpoints = useBreakpoints();
  const authApi = SutroApi.getApi().authenticate();
  const { track } = useAnalytics();
  const [isFeatureRequestDialogOpen, setIsFeatureRequestDialogOpen] =
    useState(false);
  const intercom = useIntercom();

  const openIntercom = useCallback(() => {
    track(StudioEventTypes.BUTTON_CLICK, {
      buttonId: ButtonIds.DESIGNER_ASSISTANCE_MENU_NEED_HELP,
    });
    intercom("show");
  }, [intercom]);

  const submitFeatureRequest = useCallback(
    (featureRequest: { subject: string; description: string }) => {
      const throwError = () => {
        toast.error("Failed to submit feature request");
        track(StudioEventTypes.ERROR, {
          message: "Failed to submit feature request",
        });
      };

      void authApi.post("/submitFeatureRequest", featureRequest).then(
        either(throwError).or(() => {
          toast.success("Feature request submitted successfully");
          track(StudioEventTypes.FORM_SUBMIT, {
            formId: FormIds.FEATURE_REQUEST,
          });
        })
      );
    },
    [authApi]
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          className="flex flex-row gap-2"
          testId={ButtonIds.DESIGNER_TOP_NAV_ASSISTANCE_MENU}
        >
          <HelpCircle className="size-4" />
          {breakpoints.sm && "Assistance"}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="p-3">
        <DropdownMenuItem
          className="flex cursor-pointer items-start justify-start px-4 py-3"
          onClick={() => {
            setIsFeatureRequestDialogOpen(true);
            track(StudioEventTypes.BUTTON_CLICK, {
              buttonId: ButtonIds.DESIGNER_ASSISTANCE_MENU_FEATURE_REQUEST,
            });
          }}
        >
          <Puzzle className="size-5 text-muted-foreground" />
          <div className="flex flex-col pl-3">
            <span className="text-sm">Missing a feature?</span>
            <span className="text-sm text-muted-foreground">
              Let us know what we can build next
            </span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex cursor-pointer items-start justify-start px-4 py-3"
          onClick={openIntercom}
        >
          <MessagesSquare className="size-5 text-muted-foreground" />
          <div className="flex flex-col pl-3">
            <span className="text-sm">Need immediate help?</span>
            <span className="text-sm text-muted-foreground">
              Chat with our of our developer advocates
            </span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex cursor-pointer items-start justify-start px-4 py-3"
          onClick={() => {
            track(StudioEventTypes.BUTTON_CLICK, {
              buttonId: ButtonIds.DESIGNER_ASSISTANCE_MENU_READ_DOCS,
            });
          }}
        >
          <BookOpen className="size-5 text-muted-foreground" />
          <a
            href="https://docs.withsutro.com/"
            target="_blank"
            className="flex flex-col pl-3"
            rel="noreferrer noopener"
          >
            <span className="text-sm">Understand the core better</span>
            <span className="text-sm text-muted-foreground">
              Dive deeper into our tutorials and resources
            </span>
          </a>
        </DropdownMenuItem>
      </DropdownMenuContent>

      <FeatureRequestDialog
        open={isFeatureRequestDialogOpen}
        onOpenChange={setIsFeatureRequestDialogOpen}
        onSubmit={submitFeatureRequest}
      />
    </DropdownMenu>
  );
}

export function StandardTopNavigationBar() {
  return (
    <TopNavigationBar>
      <SutroLogo />
      <TopNavigationBarLink
        label="Dashboard"
        href="/"
        icon={<Home className="size-4" />}
      />
      <div className="flex grow items-center justify-center self-stretch py-3">
        {/* Not needed right now */}
        {/* <SearchBar /> */}
      </div>
      <div className="mr-2 flex items-center space-x-2">
        <Button variant="secondary" className="px-2.5">
          <Bell className="size-5" />
        </Button>
        <ProfileMenu />
      </div>
    </TopNavigationBar>
  );
}

export function CreatorTopNavigationBar() {
  const navigate = useNavigate();

  return (
    <TopNavigationBar>
      <SutroLogo />

      <div className="flex grow items-center justify-center self-stretch py-3">
        <span className="hidden text-base font-medium text-white lg:inline">
          Create new app
        </span>
      </div>
      <Button
        variant="ghost"
        className="hover:bg-gray-700"
        onClick={() => navigate("/")}
      >
        <X className="text-white" />
      </Button>
    </TopNavigationBar>
  );
}

export function StudioTopNavigationBar() {
  const executeSave = useStudio((s) => s.executeSave);
  const flags = useFeatureFlags(
    PostHogFlags.DisableAutosave,
    PostHogFlags.PublishApp
  );
  const [, openPublishDialog] = useStudioDialog(PUBLISH_DIALOG_ID);
  const [, openDevToolsDialog] = useStudioDialog(DEV_TOOLS_DIALOG_ID);
  const { track } = useAnalytics();
  const breakpoints = useBreakpoints();

  return (
    <TopNavigationBar>
      <SutroLogo />
      <div className="flex grow items-center justify-center self-stretch py-3">
        {/* Not needed right now */}
        {/* <SearchBar /> */}
      </div>
      <div className="mr-2 flex items-center space-x-2">
        <PosthogNavButton />
        <DevToolsNavButton onClick={openDevToolsDialog} />
        {flags[PostHogFlags.PublishApp] && (
          <Button
            variant="success"
            onClick={() => {
              track(StudioEventTypes.BUTTON_CLICK, {
                buttonId: ButtonIds.DESIGNER_TOP_NAV_PUBLISH,
              });
              openPublishDialog();
            }}
          >
            <Rocket className="mr-2 size-4" />
            Publish
          </Button>
        )}
        {flags[PostHogFlags.DisableAutosave] && (
          <Button
            onClick={() => {
              void executeSave();
            }}
            className="flex flex-row gap-2"
          >
            <Save className="size-4" />
            {breakpoints.lg && "Save"}
          </Button>
        )}
        <AssistanceMenu />
        <ProfileMenu />
      </div>
    </TopNavigationBar>
  );
}

function TopNavigationBar({ children }: { children: ReactNode }) {
  return (
    <div className="sticky top-0 z-50 flex h-[68px] w-full justify-center bg-[#18181b]">
      <div className="flex w-full max-w-screen-2xl items-center px-4">
        {children}
      </div>
    </div>
  );
}
