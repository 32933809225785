import { FileUp, X } from "lucide-react";
import { ReactNode, useCallback, useEffect, useState } from "react";
import Dropzone, { Accept } from "react-dropzone";

import { Instructions } from "~/components/app/instructions";
import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";

import { Label } from "../../ui/label";

interface FileUploadProps {
  /** You can add a label to this, but if you're using it in a form, you should use the label component there instead of here */
  label?: string;
  /** Any notes you want shown below the input go here */
  notes?: ReactNode;
  /** What to do when a file is uploaded */
  onFileUpload: (file?: File) => void;
  /** Represents the error message */
  error?: string;
  /** The current file value */
  value?: File;
  accept?: Accept;
}

/**
 * This component is for uploading files.
 */
export function FileUpload({
  onFileUpload,
  label,
  notes,
  error,
  value,
  accept,
}: FileUploadProps) {
  const [fileName, setFileName] = useState<string | null>(null);

  useEffect(() => {
    if (value) {
      setFileName(value.name);
    } else {
      setFileName(null);
    }
  }, [value]);

  const onDropFiles = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) {
        return;
      }

      const file = acceptedFiles[0];
      setFileName(file.name);
      onFileUpload(file);
    },
    [onFileUpload]
  );

  const clearFile = useCallback(() => {
    setFileName(null);
    onFileUpload(undefined);
  }, [onFileUpload]);

  return (
    <Label className="gap-2 text-lg">
      {label && <span>{label}</span>}

      <div className="relative">
        {Boolean(fileName) && (
          <Button
            variant="outline"
            size="icon"
            className="absolute right-0 m-2 size-6"
            onClick={clearFile}
          >
            <X className="size-4" />
          </Button>
        )}
        <Dropzone onDrop={onDropFiles} maxFiles={1} accept={accept}>
          {({ getRootProps, getInputProps, isDragAccept }) => (
            <section>
              <div
                {...getRootProps()}
                className={cn(
                  "border-border flex h-32 w-full cursor-pointer flex-col items-center justify-center rounded-md border p-2",
                  {
                    "bg-blue-100": isDragAccept,
                    "border-red-600": error,
                  }
                )}
              >
                <input {...getInputProps()} />
                {fileName ? (
                  <div className="flex items-center justify-center">
                    <FileUp className="mr-2 w-5" />
                    <span className="text-sm font-normal">{fileName}</span>
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <FileUp className="mr-2 w-5" />
                    <span className="text-sm font-normal">
                      Drag and drop file or{" "}
                      <span className="text-primary">click here</span>
                    </span>
                  </div>
                )}
              </div>
              {error && (
                <div className="mt-2 flex items-center">
                  <span className="text-sm font-medium text-red-600">
                    {error}
                  </span>
                </div>
              )}
              {notes && <Instructions className="mt-2">{notes}</Instructions>}
            </section>
          )}
        </Dropzone>
      </div>
    </Label>
  );
}
