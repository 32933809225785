import { Rocket } from "lucide-react";
import { useFormContext } from "react-hook-form";
import { ButtonIds } from "sutro-analytics";
import { pick } from "sutro-common/collection-helpers/pick";
import { z } from "zod";
import { useShallow } from "zustand/react/shallow";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "~/components/ui/form";
import { SmallLoadingSpinner } from "~/components/ui/loading-spinner";
import {
  appPublishSchema,
  PublishRequestStatus,
  usePublish,
} from "~/lib/use-publish";

import { Button } from "../../ui/button";
import { DialogFooter, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Input } from "../../ui/input";
import { Switch } from "../../ui/switch";
import { DetailToggle } from "./detail-toggle";

const step2WebSchema = appPublishSchema.pick({
  termsOfServiceLink: true,
  privacyPolicyLink: true,
});

export function Step2Web({
  onContinue,
  onBack,
}: {
  onContinue: () => void;
  onBack: () => void;
}) {
  const { trigger, setValue, control, clearErrors } =
    useFormContext<z.infer<typeof step2WebSchema>>();

  const {
    hasTermsOfServiceAndPrivacyPolicy,
    setHasTermsOfServiceAndPrivacyPolicy,
    publishRequestStatus,
  } = usePublish(
    useShallow(
      pick(
        "hasTermsOfServiceAndPrivacyPolicy",
        "setHasTermsOfServiceAndPrivacyPolicy",
        "publishRequestStatus"
      )
    )
  );

  const handleNextStep = async () => {
    /**
     * If the publish request is still loading, don't proceed
     */
    if (publishRequestStatus === PublishRequestStatus.LOADING) {
      return;
    }

    if (hasTermsOfServiceAndPrivacyPolicy) {
      const isValid = await trigger([
        "termsOfServiceLink",
        "privacyPolicyLink",
      ]);
      if (!isValid) {
        // Validation failed, don't proceed
        return;
      }
    }
    onContinue();
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle>Publish to Web</DialogTitle>
      </DialogHeader>
      <DetailToggle
        contentLeft={
          <div className="flex flex-col">
            <span className="text-sm font-medium">
              I have Terms and Conditions and a Privacy Policy
            </span>
            <span className="text-sm text-muted-foreground">Recommended</span>
          </div>
        }
        contentRight={
          <Switch
            checked={hasTermsOfServiceAndPrivacyPolicy}
            onCheckedChange={(checked) => {
              setHasTermsOfServiceAndPrivacyPolicy(checked);
              if (!checked) {
                (["termsOfServiceLink", "privacyPolicyLink"] as const).forEach(
                  (item) => {
                    setValue(item, "");
                    clearErrors(item);
                  }
                );
              }
            }}
          />
        }
        detailContent={
          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium">
              Provide Terms and Conditions and a Privacy Policy
            </span>
            <p className="text-sm text-muted-foreground">
              It is optional for you to have Terms & Conditions and a Privacy
              Policy if you are the only person using your app. If anyone other
              than you will be using the app, you are required to provide Terms
              & Conditions and a Privacy Policy.
            </p>

            <FormField
              control={control}
              name="termsOfServiceLink"
              render={({ field, fieldState }) => (
                <FormItem className="mb-3">
                  <FormLabel>Terms and Conditions</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      error={fieldState.error?.message}
                      placeholder="e.g. https://docs.google.com/..."
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("termsOfServiceLink");
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="privacyPolicyLink"
              render={({ field, fieldState }) => (
                <FormItem className="mb-3">
                  <FormLabel>Privacy Policy</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      error={fieldState.error?.message}
                      placeholder="e.g. https://docs.google.com/..."
                      onChange={(e) => {
                        field.onChange(e);
                        clearErrors("privacyPolicyLink");
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        }
        showDetail={hasTermsOfServiceAndPrivacyPolicy}
      />
      <DialogFooter>
        <Button variant="secondary" onClick={onBack}>
          Back
        </Button>
        <Button
          onClick={handleNextStep}
          testId={ButtonIds.PUBLISH_FLOW_FINISH_PUBLISH}
        >
          <div className="flex items-center gap-2">
            {publishRequestStatus === PublishRequestStatus.LOADING ? (
              <>
                <SmallLoadingSpinner />
                <span>Please wait...</span>
              </>
            ) : (
              <>
                <Rocket className="size-4" />
                <span>Publish</span>
              </>
            )}
          </div>
        </Button>
      </DialogFooter>
    </>
  );
}
