import { create } from "zustand";

import config, { AppConfig } from "~/app.config";

export interface AppConfigStore {
  appConfig: AppConfig;
  setAppConfig: (newOptions: Partial<Pick<AppConfig, "isDev">>) => void;
}

export const useAppConfig = create<AppConfigStore>()((set) => {
  const setAppConfig = (newOptions: Partial<Pick<AppConfig, "isDev">>) => {
    set(({ appConfig: options }) => ({
      appConfig: { ...options, ...newOptions },
    }));
  };
  return {
    appConfig: config,
    setAppConfig,
  };
});
