import { useFormContext } from "react-hook-form";
import { ButtonIds } from "sutro-analytics";
import { z } from "zod";

import { Button } from "~/components/ui/button";
import {
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "~/components/ui/form";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { RadioGroup, RadioGroupItem } from "~/components/ui/radio-group";
import { AppleTeamType, appPublishSchema } from "~/lib/use-publish";

import { FileUpload } from "../file-upload";

const step4IosSchema = appPublishSchema.pick({
  ascApiKeyFile: true,
  ascKeyId: true,
  ascIssuerId: true,
  appleTeamId: true,
  appleTeamType: true,
});

export function Step4Ios({
  onBack,
  onContinue,
}: {
  onBack: () => void;
  onContinue: () => void;
}) {
  const { trigger, control, clearErrors } =
    useFormContext<z.infer<typeof step4IosSchema>>();

  const handleNextStep = async () => {
    const isValid = await trigger(["ascKeyId", "ascIssuerId", "appleTeamId"]);

    if (isValid) {
      onContinue();
    }
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle>App Store Connect (ASC) API key</DialogTitle>
      </DialogHeader>

      <div className="mt-2 flex flex-col gap-2">
        <FormField
          control={control}
          name="ascApiKeyFile"
          render={({ field, fieldState }) => (
            <FileUpload
              {...field}
              label="ASC API key file"
              accept={{ "application/octet-stream": [".p8"] }}
              notes=".p8 file generated from your Apple Developer account"
              error={fieldState.error?.message}
              onFileUpload={(file) => {
                field.onChange(file);
                clearErrors("ascApiKeyFile");
              }}
            />
          )}
        />

        <FormField
          control={control}
          name="ascKeyId"
          render={({ field, fieldState }) => (
            <FormItem className="mb-3">
              <FormLabel>Key identifier</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  error={fieldState.error?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors("ascKeyId");
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="ascIssuerId"
          render={({ field, fieldState }) => (
            <FormItem className="mb-3">
              <FormLabel>Issuer identifier</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  error={fieldState.error?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors("ascIssuerId");
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="appleTeamId"
          render={({ field, fieldState }) => (
            <FormItem className="mb-3">
              <FormLabel>Apple Team identifier</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  error={fieldState.error?.message}
                  placeholder=""
                  onChange={(e) => {
                    field.onChange(e);
                    clearErrors("appleTeamId");
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="appleTeamType"
          render={({ field }) => (
            <FormItem className="mb-3">
              <FormLabel>Apple developer account type</FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={(value) => {
                    field.onChange(value);
                    clearErrors("appleTeamType");
                  }}
                  value={field.value}
                  className="mt-2 flex items-center gap-4"
                >
                  <FormItem className="flex items-center gap-2">
                    <FormControl>
                      <RadioGroupItem
                        value={AppleTeamType.INDIVIDUAL}
                        className="border-primary text-primary"
                      />
                    </FormControl>
                    <Label className="!mt-0 font-normal">Individual</Label>
                  </FormItem>
                  <FormItem className="flex items-center gap-2">
                    <FormControl>
                      <RadioGroupItem
                        value={AppleTeamType.COMPANY_OR_ORGANIZATION}
                        className="border-primary text-primary"
                      />
                    </FormControl>
                    <Label className="!mt-0 font-normal">
                      Company or Organization
                    </Label>
                  </FormItem>
                </RadioGroup>
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <DialogFooter>
        <Button
          variant="secondary"
          onClick={onBack}
          testId={ButtonIds.PUBLISH_FLOW_PREVIOUS_STEP}
        >
          Back
        </Button>
        <Button
          onClick={handleNextStep}
          testId={ButtonIds.PUBLISH_FLOW_NEXT_STEP}
        >
          Continue
        </Button>
      </DialogFooter>
    </>
  );
}
