import { ReactNode } from "react";

export function DetailToggle({
  contentLeft,
  contentRight,
  detailContent,
  showDetail,
}: {
  contentLeft: ReactNode;
  contentRight: ReactNode;
  detailContent?: ReactNode;
  showDetail?: boolean;
}) {
  return (
    <div className="flex w-full flex-col rounded border">
      <div className="flex items-center justify-between p-4">
        <div className="grow">{contentLeft}</div>
        <div>{contentRight}</div>
      </div>
      {showDetail && Boolean(detailContent) && (
        <div className="border-t p-4">{detailContent}</div>
      )}
    </div>
  );
}
