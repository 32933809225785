import { FlaskConical } from "lucide-react";
import posthogJs from "posthog-js";

import { Button } from "~/components/ui/button";
import { useAppConfig } from "~/lib/hooks/use-app-config";
import useBreakpoints from "~/lib/hooks/use-breakpoint";
import { cn } from "~/lib/utils";

const loadPosthog = () => {
  posthogJs.loadToolbar({});
};
/**
 * This is a convenience button.
 *
 * It doesn't work _perfectly_ because the PostHog toolbar is not designed to be opened and closed multiple times.
 *
 * Once you open the toolbar, if you close it, you can't reopen it with the button. You have to refresh the page.
 */
export const PosthogNavButton = () => {
  const breakpoints = useBreakpoints();
  const { appConfig } = useAppConfig();
  return (
    <Button
      variant="secondary"
      className={cn("flex cursor-pointer flex-row gap-2", {
        hidden: !appConfig.isDev,
      })}
      onClick={loadPosthog}
    >
      <FlaskConical className="size-4 text-red-500" />
      {breakpoints.lg && "PostHog"}
    </Button>
  );
};
