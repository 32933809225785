import {
  StudioEntitlement,
  SubscriptionTier,
  TierName,
} from "sutro-common/studio-entitlements";

export type PricingTierData = {
  /**
   * The name of the tier
   */
  tierName: TierName;
  /**
   * The monthly rate for this tier
   */
  price: number;
  /**
   * A short description of the tier
   */
  description: string;
  /**
   * A short description of what this tier offers
   */
  offeringDescription: string;
  /**
   * A list of features that this tier provides
   */
  checkMarks: string[];
  /**
   * A list of limitations of this tier
   */
  xMarks?: string[];
};

export const PRICING_TIERS: PricingTierData[] = [
  {
    tierName: "Free",
    price: 0,
    description: "Quickly build prototypes",
    offeringDescription: "Get access to Sutro Studio",
    checkMarks: ["Mobile-friendly prototypes"],
    xMarks: ["No app publishing"],
  },
  {
    tierName: "Starter",
    price: 99,
    description: "Launch your MVP",
    offeringDescription: "Everything in Free, plus",
    checkMarks: [
      "Publish app to Web",
      "Up to 1 published app",
      "Customizable design theme",
      "Full-hosted backend",
      "Custom subdomain",
    ],
  },
  {
    tierName: "Pro",
    price: 249,
    description: "Build your business",
    offeringDescription: "Everything in Starter, plus",
    checkMarks: [
      "Publish app to iOS and Android",
      "Up to 5 published apps",
      "Payments and subscriptions",
      "Usage analytics",
      "Custom domain",
    ],
  },
  {
    tierName: "Custom",
    price: -1,
    description: "Expand your product capabilities",
    offeringDescription: "Everything in Pro, plus",
    checkMarks: [
      "Custom features",
      "Unlimited published apps",
      "Fully customizable design",
      "Regulatory compliance",
      "Database management",
    ],
  },
];

const ENTITLEMENTS_MAP: Record<string, string> = {
  "publish-to-mobile-web": "Publish app to the Web",
  "customize-design-theme": "Customizable design theme",
  "fully-hosted-backend": "Full-hosted backend",
  "custom-subdomain": "Custom subdomain",
  "publish-to-app-store": "Publish app to iOS",
  "publish-to-google-play": "Publish app to Android",
  "payments-and-subscriptions": "Payments and subscriptions",
  "custom-domain": "Custom domain",
  "usage-analytics": "Usage analytics",
  "custom-features": "Custom features",
  "fully-customizable-design": "Fully customizable design",
  "regulatory-compliance": "Regulatory compliance",
  "database-management": "Database management",
  "publishing-limit-1": "Up to 1 published app",
  "publishing-limit-5": "Up to 5 published apps",
  "publishing-limit-unlimited": "Unlimited published apps",
};

export const getCustomTierPricingData = (
  tier: SubscriptionTier,
  entitlements: StudioEntitlement[]
): PricingTierData => {
  const tierName = tier.name;
  const price = tier.price.unitAmount;
  const checkMarks = entitlements
    .map((entitlement) => ENTITLEMENTS_MAP[entitlement.entitlementKey])
    .filter(Boolean);

  const description = "Build apps on a custom plan";
  const offeringDescription = "Everything in Pro, plus";

  if (checkMarks.length > 6) {
    checkMarks.splice(5, checkMarks.length - 5, "and more...");
  }

  return {
    tierName,
    price,
    description,
    offeringDescription,
    checkMarks,
  };
};
