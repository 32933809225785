export function PlatformAndroidIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1453_4334)">
        <path
          d="M7.66839 11.4331H24.5926V25.2246C24.5926 26.0439 23.9284 26.7081 23.1101 26.7081H21.4149V30.9083C21.4149 31.9749 20.5641 32.8394 19.514 32.8394C18.4611 32.8394 17.6116 31.9749 17.6116 30.9083V26.7081H14.6509V30.9083C14.6509 31.9749 13.7985 32.8394 12.7483 32.8394C11.6984 32.8394 10.846 31.9749 10.846 30.9083V26.7081H9.15233C8.33383 26.7081 7.66833 26.0439 7.66833 25.2246L7.66839 11.4331ZM4.35183 11.3206C3.29095 11.3206 2.43164 12.1933 2.43164 13.2706V20.8916C2.43164 21.9677 3.29095 22.8419 4.35183 22.8419C5.41289 22.8419 6.27202 21.9677 6.27202 20.8916V13.2706C6.27202 12.1933 5.41289 11.3206 4.35183 11.3206ZM24.5927 10.0434H7.66839C7.87727 7.63226 9.44133 5.55738 11.6929 4.356L10.0838 1.99325C9.86677 1.67463 9.94864 1.23988 10.2679 1.02294C10.5868 0.806004 11.0206 0.888504 11.2387 1.20688L12.9925 3.78388C13.9649 3.44763 15.0204 3.25457 16.1305 3.25457C17.242 3.25457 18.2975 3.44763 19.2698 3.78432L21.0236 1.20782C21.239 0.888566 21.6743 0.806066 21.9931 1.023C22.3124 1.23994 22.3943 1.67469 22.1773 1.99332L20.5695 4.35607C22.821 5.55669 24.384 7.63157 24.5927 10.0434ZM13.4971 6.76719C13.4971 6.25013 13.0784 5.83075 12.5615 5.83075C12.0433 5.83075 11.6246 6.25013 11.6246 6.76719C11.6246 7.284 12.0447 7.70319 12.5615 7.70319C13.0783 7.70319 13.4971 7.284 13.4971 6.76719ZM20.755 6.76719C20.755 6.25013 20.3349 5.83075 19.8181 5.83075C19.3 5.83075 18.8825 6.25013 18.8825 6.76719C18.8825 7.284 19.3 7.70319 19.8181 7.70319C20.335 7.70319 20.755 7.284 20.755 6.76719ZM27.9105 11.3179C26.8508 11.3179 25.989 12.1919 25.989 13.2693V20.893C25.989 21.9704 26.8508 22.8446 27.9105 22.8446C28.9716 22.8446 29.8293 21.9704 29.8293 20.893V13.2693C29.8294 12.1919 28.9716 11.3179 27.9105 11.3179Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1453_4334">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.130859 0.871094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
