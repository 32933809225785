import { ComponentProps } from "react";
import { isEmpty, not } from "sutro-common";
import { StudioUserProfile } from "sutro-common/sutro-data-store-types";

import { useGravatar } from "~/lib/use-gravatar";

import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

export function StudioUserAvatar({
  className,
  studioUser,
  preview = false,
}: { preview?: boolean; studioUser: StudioUserProfile | null } & ComponentProps<
  typeof Avatar
>) {
  const avatarSource = studioUser?.avatar?.source ?? "default";
  const gravatarUrl = useGravatar({ force: preview });

  const nameOfUser =
    avatarSource === "upload"
      ? "?"
      : [
          studioUser?.knownAs,
          studioUser?.fullName,
          studioUser?.username,
          studioUser?.identity,
        ].find(not(isEmpty));

  const avatarUrl = studioUser?.avatar?.image ?? gravatarUrl ?? undefined;

  return (
    <Avatar className={className}>
      <AvatarImage src={avatarUrl} alt={nameOfUser} height={128} width={128} />
      <AvatarFallback>
        {nameOfUser ? nameOfUser[0].toUpperCase() : "?"}
      </AvatarFallback>
    </Avatar>
  );
}
