import * as RadioGroup from "@radix-ui/react-radio-group";
import { ArrowUpCircle } from "lucide-react";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ButtonIds, StudioEventTypes } from "sutro-analytics";
import { DEVICE_PLATFORMS } from "sutro-common";
import { PostHogFlags } from "sutro-common/feature-flags/posthog-flags";
import { isTierUpgrade } from "sutro-common/studio-entitlements";
import { Product } from "sutro-common/sutro-data-store-types";
import { z } from "zod";

import { Button } from "~/components/ui/button";
import {
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "~/components/ui/dialog";
import { PremiumWrapper } from "~/components/ui/premium-wrapper";
import { useFeatureFlags } from "~/lib/hooks/use-feature-flag";
import { useStudio } from "~/lib/hooks/use-studio";
import { useAnalytics } from "~/lib/use-analytics";
import { appPublishSchema } from "~/lib/use-publish";
import { cn } from "~/lib/utils";
import { useEntitlements } from "~/providers/EntitlementsProvider/entitlements-provider";

import { PlatformAndroidIcon } from "./platform-android-icon";
import { PlatformIosIcon } from "./platform-ios-icon";
import { PlatformRadioItem } from "./platform-radio-item";
import { PlatformWebIcon } from "./platform-web-icon";

const step1Schema = appPublishSchema.pick({
  platform: true,
});

function UpgradeButton({
  onRequestUpgrade,
  message,
}: {
  onRequestUpgrade: () => void;
  message: string;
}) {
  return (
    <Button
      className="flex flex-row justify-between gap-2 border border-warning-strong bg-warning-strong px-4 py-2 text-black hover:bg-warning-strong/80"
      onClick={onRequestUpgrade}
    >
      <ArrowUpCircle size={20} />
      <div className="w-min">{message}</div>
    </Button>
  );
}

function FreeTierWarning({
  onRequestUpgrade,
}: {
  onRequestUpgrade: () => void;
}) {
  return (
    <div className="flex flex-row content-center justify-between gap-x-1 rounded-md border border-border-warning-muted bg-bg-warning-muted py-4 pl-4 pr-6">
      <div className="flex flex-col gap-1 text-warning">
        <div className="text-sm font-semibold leading-5">
          Upgrade now to publish your app!
        </div>
        <div className="text-xs font-normal leading-4">
          You&apos;re currently on the Free plan
        </div>
      </div>
      <UpgradeButton
        onRequestUpgrade={onRequestUpgrade}
        message="Upgrade now"
      />
    </div>
  );
}

export function Step1({
  publishedProducts,
  onContinue,
  onCancel,
  onRequestUpgrade,
}: {
  publishedProducts: Product[];
  onContinue: () => void;
  onCancel: () => void;
  onRequestUpgrade: () => void;
}) {
  const { entitlements, currentSubscription } = useEntitlements();
  const flags = useFeatureFlags(PostHogFlags.PremiumFeatures);
  const canPublish =
    !flags[PostHogFlags.PremiumFeatures] ||
    entitlements.some((e) => e.entitlementKey === "publish-app");
  const { setValue, watch } = useFormContext<z.infer<typeof step1Schema>>();
  const platform = watch("platform");
  const { track } = useAnalytics();

  const { product } = useStudio();

  const isPublishedOnWeb = useMemo(() => {
    return Boolean(product?.productionVersionId);
  }, [product]);

  const maxNumberOfPublishedApps = useMemo(() => {
    let max: number | null = 0;

    const publishMaxEntitlement = entitlements.find((e) =>
      e.entitlementKey.startsWith("publishing-limit-")
    );
    if (publishMaxEntitlement) {
      // e.g. publishing-limit-5
      max = parseInt(publishMaxEntitlement.entitlementKey.split("-").pop()!);
    } else if (entitlements.length > 0) {
      // If there are entitlements but no publishing-limit-X, then we're on a custom plan
      max = null;
    }

    return max;
  }, [entitlements]);

  const handleNextStep = useCallback(() => {
    track(StudioEventTypes.BUTTON_CLICK, {
      buttonId: ButtonIds.PUBLISH_FLOW_CHOOSE_PLATFORM,
      platform,
    });
    onContinue();
  }, [platform, onContinue, track]);

  const showUpgradeButton =
    !canPublish && isTierUpgrade(currentSubscription.tier.name, "Pro");
  const showUpgradeWarning =
    !canPublish && currentSubscription.tier.name === "Free";

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <DialogTitle>Publish app</DialogTitle>
        <DialogDescription>
          {currentSubscription.tier.name === "Free" ||
          !publishedProducts.length ? (
            <>
              Which platform do you want to publish your app on? To publish your
              app on more than one platform, simply complete the process for one
              platform and click “Publish” in the studio to begin the process
              again for another platform.
            </>
          ) : (
            <>
              {publishedProducts.length}/
              {maxNumberOfPublishedApps === null
                ? "?"
                : maxNumberOfPublishedApps}{" "}
              apps published in your workspace based on your{" "}
              {currentSubscription.tier.name} plan.
            </>
          )}
        </DialogDescription>
      </div>
      {showUpgradeWarning && (
        <FreeTierWarning onRequestUpgrade={onRequestUpgrade} />
      )}

      <RadioGroup.Root
        value={platform}
        onValueChange={(platform) => {
          setValue("platform", platform as DEVICE_PLATFORMS);
        }}
        className="flex flex-col gap-2"
      >
        <PremiumWrapper tier="Starter" entitlementKey="publish-to-mobile-web">
          <PlatformRadioItem
            platform={DEVICE_PLATFORMS.MOBILE_WEB}
            icon={<PlatformWebIcon />}
            title="Web"
            description="Recommended"
            published={isPublishedOnWeb}
            disabled={
              maxNumberOfPublishedApps !== null &&
              publishedProducts.length >= maxNumberOfPublishedApps
            }
          />
        </PremiumWrapper>
        <PremiumWrapper tier="Pro" entitlementKey="publish-to-app-store">
          <PlatformRadioItem
            platform={DEVICE_PLATFORMS.IOS}
            icon={<PlatformIosIcon />}
            title="iOS"
            description=""
            disabled={
              maxNumberOfPublishedApps !== null &&
              publishedProducts.length >= maxNumberOfPublishedApps
            }
          />
        </PremiumWrapper>
        <PremiumWrapper tier="Pro" entitlementKey="publish-to-google-play">
          <PlatformRadioItem
            platform={DEVICE_PLATFORMS.ANDROID}
            icon={<PlatformAndroidIcon />}
            title="Android"
            description=""
            disabled={
              maxNumberOfPublishedApps !== null &&
              publishedProducts.length >= maxNumberOfPublishedApps
            }
          />
        </PremiumWrapper>
      </RadioGroup.Root>
      <DialogFooter className="w-full">
        <div
          className={cn({
            "flex w-full flex-row justify-between": showUpgradeButton,
          })}
        >
          {showUpgradeButton && (
            <div className="flex grow flex-row justify-start">
              <UpgradeButton
                onRequestUpgrade={onRequestUpgrade}
                message="Upgrade to Pro"
              />
            </div>
          )}
          <div className="flex flex-row gap-2">
            <Button
              variant="secondary"
              onClick={onCancel}
              testId={ButtonIds.PUBLISH_FLOW_CANCEL}
            >
              Cancel
            </Button>
            <Button
              disabled={!platform}
              onClick={handleNextStep}
              type="button"
              testId={ButtonIds.PUBLISH_FLOW_NEXT_STEP}
            >
              Continue
            </Button>
          </div>
        </div>
      </DialogFooter>
    </div>
  );
}
