import { lazy, Suspense, useState } from "react";

import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { Switch } from "~/components/ui/switch";
import { useStudio } from "~/lib/hooks/use-studio";

const Editor = lazy(() =>
  import("json-edit-react").then((m) => ({
    default: m.JsonEditor,
  }))
);

export const TreeViewer = () => {
  const interpreterBundle = useStudio((s) => s.interpreterBundle);
  const [search, setSearch] = useState("");
  const [collapse, setCollapse] = useState(5);

  return (
    <div className="flex size-full flex-col items-center overflow-hidden pb-5">
      <div className="flex w-full flex-1 flex-col overflow-auto p-4">
        <div className="flex w-full items-center justify-between">
          <Label className="mb-2 gap-2">
            <span>Search for any value in the tree</span>
            <Input
              placeholder=""
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Label>
          <div className="flex items-center space-x-2">
            <Switch
              id="collapse"
              checked={collapse === 0}
              onCheckedChange={() =>
                setCollapse((current) => (current === 0 ? 5 : 0))
              }
            />
            <Label htmlFor="collapse">Collapse</Label>
          </div>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <Editor
            minWidth="100%"
            maxWidth="100%"
            data={interpreterBundle?.tree ?? {}}
            collapse={collapse}
            restrictEdit={true}
            searchFilter="all"
            searchText={search}
            className="h-full flex-1 overflow-auto"
            theme="default"
          />
        </Suspense>
      </div>
    </div>
  );
};
